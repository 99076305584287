/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
// import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, meta, title, canonicalLink, script, faqSchema }) {
  const metaDescription = description || "";
  const ogTitle = meta && meta.ogTitle ? meta.ogTitle : title;
  const scriptJob = script ? script : null;
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      link={[{ rel: "canonical", href: canonicalLink }]}
      script={scriptJob}
      titleTemplate={`%s`}
      meta={[
        {
          name: `title`,
          content: title,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: meta.ogImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  meta: {},
  description: ``,
  canonicalLink: ``,
  script: [],
  faqSchema: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  // meta: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  title: PropTypes.string.isRequired,
  canonicalLink: PropTypes.string,
  script: PropTypes.array,
  faqSchema: PropTypes.array,
};

export default SEO;
