import React, { useState, useEffect, createRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledButton from "../shared/StyledButton";

// import { Icon } from "../../globals/icons";
import {
  StyledDesc2,
  StyledDesc3,
} from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { textCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import { furStyleState, smileState, hairState, apparelState, accessoriesState, technologyState, bgState, badgeState } from "./AvatarState.js"

import hasuraConBrand from "./images/hasura-con.svg";
import hasuraPoweredBrandWhite from "./images/hasura-powerd-white.svg";
import twitterIcon from "./images/twitter-icon.svg";
import linkedinIcon from "./images/linkedin-icon.svg";
import downloadIcon from "./images/download-icon.svg";
import patternIcon from "./avatarimages/pattern-icon.png";
import contentIcon from "./images/content-icon.svg";


import leftArrow from "./avatarimages/chevron_left.svg";
import rightArrow from "./avatarimages/chevron_right.svg";
import axios from "axios";

import mergeImages from "merge-images";

import { Link } from "gatsby";
import { scrollToTop } from "../common/Header/helper";

import { hasuraCon22UploadUrl } from "../../endpoints";

import { isSafari } from 'react-device-detect';
import Notifications, {notify} from 'react-notify-toast';


const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
};

const StyledSectionWrapperBg = styled(StyledSectionWrapper)`
  background-color: ${COLORS.black};
  padding-top: 0;
  padding-bottom: 0;
`;

const StyledCreateAvatarWrapper = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 386px;
  grid-gap: 74px; */
  /* padding-top: 60px; */
  .mWidth386 {
    max-width: 386px;
    margin: 0 auto;
  }
  .avatarCreateTab {
    display: flex;
    align-items: center;
    padding-bottom: 48px;
    li {
      margin: 0 16px;
      padding-bottom: 7px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .active {
      border-bottom: 2px solid ${COLORS.sky};
    }
  }
  .avatarCreateImg {
    margin-bottom: 32px;
    position: relative;
    /* background-color: #fff;
    border-radius: 16px; */
    img {
      width: 386px;
      height: 386px;
      /* border-radius: 16px; */
      z-index: 1;
      position: relative;
    }
    .avatarPos {
      position: absolute;
      top: 0;
      left: 0;
    }
    .badgePos {
      top: 1px;
      left: -1px;
    }
    .zIndexOne {
      z-index: 1;
    }
    .techIcon {
      position: absolute;
      right: 80px;
      bottom: 30px;
      width: 50px;
      height: 50px;
      border-radius: 0;
      z-index: 10;
    }
    .stampWrapper {
      background-color: #141C22;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      bottom: -40px;
      z-index: 1;
      padding: 0 20px;
      .attandingImg {
        width: 176px;
        margin-top: -30px;
        margin-top: -46px;
        margin-left: -14px;
      }
    }
  }
  .smShow {
    display: none;
  }
  .mobileHide {

  }
  .mobileShow {
    display: none;
  }
  .buttonWrapper {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    padding-bottom: 24px;
    position: relative;
    .shareBtn {
      padding: 15px 26px;
      border-radius: 8px;
      border: 0;
      img {
        min-width: 18px;
      }
    }
    .downloadBtn {
      background-color: #1EB4D4;
    }
    .twitterBtn {
      background-color: #1DA1F2;
    }
    .linkedInBtn {
      background-color: #0077B5;
    }
    .copyBtn {
      background-color: #37A476;
    }
  }
  .footerAvatar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 32px;
    padding-top: 20px;
  }
  .furStyleWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 48px 96px;
    max-width: 456px;
  }
  .propertyWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 24px 0px;
    padding-bottom: 100px;
  }
  .propertyTechWrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
  }
  .furStyleWrapper, .propertyWrapper {
    .radiusCircle, .radiusRectangle {
      display: flex;
      cursor: pointer;
    }
    .radiusCircle {
      width: 88px;
      height: 88px;
      border-radius: 50%;
      border: 8px solid transparent;
    }

    .radiusRectangle {
      width: 94px;
      height: 94px;
      border-radius: 22px;
      background-color: #BACEDF;
      border: 8px solid transparent;
      img {
        border-radius: 14px;
      }
    }
    .technology {
      background-color: #23303D;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50px;
        border-radius: 0px;
      }
    }
    .active {
      border: 8px solid #39424F;
    }
  }
  .propertyTabWrapper {
    display: flex;
    padding-bottom: 28px;
    margin-top: -20px;
    border-bottom: .5px solid ${COLORS.grey_10};
    margin-bottom: 28px;
    .propertyTab {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin: 0 8px;
      border: 8px solid transparent;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .patternIcon {
        min-width: 56px;
        margin-top: -8px;
      }
      .patternActive {
        outline: 8px solid #39424F;
        outline-offset: -8px;
        border-radius: 50%;
      }
    }
    .blackBg {
      background-color: #000;
      border: .5px solid #fff;
    }
    .blondBg {
      background-color: #f5af44;
    }
    .brownBg {
      background-color: #5b3e3d;
    }
    .greenBg {
      background-color: #117063;
    }
    .blueBg {
      background-color: #013568;
    }
    .whiteBg {
      background-color: #ffffff;
    }
    .greyBg {
      background-color: #292b2e;
    }
    .orangeBg {
      background-color: #ca7644;
    }
    .blueBg {
      background-color: #22b4d4;
    }
    .active {
      border: 8px solid #39424F;
    }
  }
  ${mq.between("lg", "xl")} {
    grid-gap: 24px;
    .avatarCreateTab {
      li {
        margin: 0 8px;
      }
    }
    .propertyWrapper {
      grid-gap: 24px 0px;
      .radiusRectangle {
        width: 85px;
        height: 85px;
      }
    }
  }
  ${mq.below.lg} {
    grid-template-columns: 1fr;
    .buttonWrapper {
      padding-top: 40px;
    }
    .mobileShow {
      display: block;
    }
    .mobileHide {
      display: none;
    }
    .avatarCreateImg {
      max-width: 386px;
      margin: 0 auto;
      margin-bottom: 100px;
    }
    .mobileMarAuto {
      margin: 0 auto;
    }
    .furStyleWrapper {
      grid-gap: 48px;
      max-width: unset;
    }
    .propertyWrapper {
      padding-bottom: 0;
    }
    .furStyleWrapper, .propertyWrapper {
      .radiusCircle {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 6px solid transparent;
      }
      .radiusRectangle {
        background-color: #BACEDF;
        border: 6px solid transparent;
      }
      .active {
        border: 6px solid #39424F;
      }
    }
    .propertyTabWrapper {
      .propertyTab {
        width: 32px;
        height: 32px;
        border: 6px solid transparent;
        margin: 0 6px;
        .patternIcon {
          min-width: 32px;
          margin-top: -10px;
        }
        .patternActive {
          outline: 6px solid #39424F;
          outline-offset: -6px;
        }
      }
      .blackBg {
        border: 0.5px solid #fff;
      }
      .active {
        border: 6px solid #39424F;
      }
    }
  }
  ${mq.below.md} {
    .avatarCreateImg {
      margin-bottom: 70px;
    }
    .avatarCreateTab {
      li {
        margin: 0 12px;
      }
    }
  }
  ${mq.below.avatarSm} {
    .mobileMarAuto {
      margin: 0 0;
      .carouselWrapper {
        display: grid;
        margin-bottom: 32px;
        .slick-prev:before {
          content: url(${leftArrow});
        }
        .slick-next:before {
          content: url(${rightArrow});
        }
        .slick-next {
          right: -15px;
        }
        .carouselTab {
          margin: 0 16px;
          padding-bottom: 7px;
          border-bottom: 2px solid transparent;
          cursor: pointer;
          display: inline-block;
          margin-top: 10px;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .active {
          border-bottom: 2px solid ${COLORS.sky};
        }
      }
    }
    .smHide {
      display: none;
    }
    .smShow {
      display: block;
    }
  }
  ${mq.below.sm} {
    grid-gap: 10px;
    .propertyWrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .buttonWrapper {
      padding-top: 32px;
      .mBtn {
        padding: 12px 12px;
        .rightBtnIcon {
          margin-left: 4px;
        }
      }
    }
    .footerAvatar {
      padding-top: 32px;
      flex-direction: column;
      justify-content: center;
      .hasuraPowerdBrand {
        padding-top: 20px;
      }
    }
    .avatarCreateImg {
      margin-bottom: 32px;
      position: relative;
      /* background-color: #fff; */
      border-radius: 16px;
      max-width: unset;
      img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }
      .avatarPos {
        position: absolute;
        top: 0;
        left: 0;
      }
      .zIndexOne {
        z-index: 1;
      }
    }
  }
  ${mq.below.xs} {
    .buttonWrapper {
      .mBtn {
        padding: 12px 12px;
        .rightBtnIcon {
          display: none;
        }
      }
    }
  }
`;

const StyledAvatarHeroBanner = styled.div`
  display: grid;
  grid-template-columns: 6fr 5fr;
  min-height: 100vh;
  .pl4 {
    padding-left: 10%;
  }
  .pr4 {
    padding-right: 10%;
  }
  .mobileHide {

  }
  .mobileShow {
    display: none;
  }
  .greyBgColor, .greyBgColorMobile {
    background-color: #111517;
  }
  .mb100 {
    margin-bottom: 70px;
  }
  .hasuraConBrand {
    padding: 20px 0;
    img {
      width: 141px;
    }
  }
  .hasuraConHeaderNav {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 30px 0;
    min-height: 97px;
    li {
      padding: 0 20px;
      &:last-child {
        padding-right: 0;
      }
      .buttonWrapper {
      }
    }
    .navBtnHide {
      display: none;
    }
  }
  .mW650 {
    max-width: 650px;
  }
  .positionSticky {
    position: sticky;
    top: 120px;
  }
  ${mq.between("lg", "xl")} {
    grid-template-columns: 5fr 4fr;
    grid-gap: 20px;
    .pl4 {
      padding-left: 32px;
    }
    .pr4 {
      padding-right: 32px;
    }
  }
  ${mq.below.lg} {
    grid-template-columns: 1fr;
    .pl4 {
      padding: 0 5%;
    }
    .pr4 {
      padding: 0 5%;
    }
    .greyBgColor {
      background-color: transparent;
    }
    .hasuraConBrand {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .mb100 {
      margin-bottom: 20px;
    }
    .mobileShow {
      display: block;
    }
    .mobileHide {
      display: none;
    }
    .mW650 {
      margin: 0 auto;
      margin-top: 30px;
      z-index: 2;
      position: relative;
    }
  }
  ${mq.below.sm} {
    .pl4 {
      padding-left: 32px;
    }
    .pr4 {
      padding-right: 32px;
    }
    .mW650 {
      margin-top: 30px;
    }
    .hasuraConBrand {
      img {
        width: 120px;
      }
    }
    .hasuraConHeaderNav {
      .avatarNavBtn {
        padding: 10px 14px;
      }
    }

  }
  ${mq.above.xxxl} {
    .pl4 {
      padding-left: 20%;
    }
    .pr4 {
      padding-right: 20%;
    }
    .hasuraConBrand, .hasuraConHeaderNav {
      padding-top: 10%;
    }
  }
`;

const CreateAvatar = ({ location }) => {
  const [avatarSelectState, setAvatarSelectState] = useState("FurStyle");
  const [propertyType, setPropertyType] = useState("property");

  const [bgStylePropertyType, setBgStylePropertyType] = useState("property");
  const [currentBgStyleSelect, setCurrentBgStyleSelect] = useState("bg-21");

  const [furStylePropertyType, setFurStylePropertyType] = useState("property");
  const [currentFurStyleSelect, setCurrentFurStyleSelect] = useState("fur-1");

  const [smilePropertyType, setSmilePropertyType] = useState("property");
  const [currentSmileSelect, setCurrentSmileSelect] = useState("smile-1");

  const [badgePropertyType, setBadgePropertyType] = useState("property");
  const [currentBadgeSelect, setCurrentBadgeSelect] = useState("has-con-badge");

  const [hairColorType, setHairColorType] = useState("black");
  const [currentHairSelect, setCurrentHairSelect] = useState("");

  const [apparelColorType, setApparelColorType] = useState("black");
  const [currentApparelSelect, setCurrentApparelSelect] = useState("");

  const [accessoriesPropertyType, setAccessoriesPropertyType] = useState("property");
  const [currentAccessoriesSelect, setCurrentAccessoriesSelect] = useState("");

  const [technologyPropertyType, setTechnologyPropertyType] = useState("light");
  const [currentTechnologySelect, setCurrentTechnologySelect] = useState("");

  const [currentAvatarBg, setCurrentAvatarBg] = useState(null);
  const [currentAvatarFurStyle, setCurrentAvatarFurStyle] = useState(furStyleState[furStylePropertyType][0]);
  const [currentAvatarSmile, setCurrentAvatarSmile] = useState(smileState[smilePropertyType][0]);
  const [currentAvatarBadge, setCurrentAvatarBadge] = useState(badgeState[badgePropertyType][0]);
  const [currentAvatarHair, setCurrentAvatarHair] = useState(null);
  const [currentAvatarApparel, setCurrentAvatarApparel] = useState(null);
  const [currentAvatarAccessories, setCurrentAvatarAccessories] = useState(null);
  const [currentTechnology, setCurrentTechnology] = useState(null);
  const [hasConId, setHasConId] = useState("");
  const [regName, setRegName] = useState("");
  const [copySuccess, setCopySuccess] = useState('');
  const [shareLink, setShareLink] = useState('');


  const showAvatarBgStyleImg = (id) => {
    if (currentBgStyleSelect === id) {
      setCurrentBgStyleSelect("bg-21");
    } else {
      setCurrentBgStyleSelect(id);
    }
  };

  const showAvatarFurStyleImg = (id) => {
    setCurrentFurStyleSelect(id);
  };

  const showAvatarSmileImg = (id) => {
    if (currentSmileSelect === id) {
      setCurrentSmileSelect("smile-1")
    } else {
      setCurrentSmileSelect(id);
    }
  }

  const showAvatarHairImg = (id) => {
    if (currentHairSelect === id) {
      setCurrentHairSelect("");
    } else {
      setCurrentHairSelect(id);
    }
  }

  const showAvatarApparelImg = (id) => {
    if (currentApparelSelect === id) {
      setCurrentApparelSelect("");
    } else {
      setCurrentApparelSelect(id);
    }
  }

  const showAvatarAccessoriesImg = (id) => {
    if (currentAccessoriesSelect === id) {
      setCurrentAccessoriesSelect("");
    } else {
      setCurrentAccessoriesSelect(id);
    }
  }

  const showTechnologyImg = (id) => {
    if (currentTechnologySelect === id) {
      setCurrentTechnologySelect("");
    } else {
      setCurrentTechnologySelect(id);
    }
  }

  const changeHairColor = (color) => {
    let currentHairSelectSplit = ""
    currentHairSelectSplit = currentHairSelect.split("-");
    setCurrentHairSelect(currentHairSelectSplit[0]+"-"+color+"-"+currentHairSelectSplit[2]);
  }

  const changeApparelColor = (color) => {
    let currentApparelSelectSplit = ""
    currentApparelSelectSplit = currentApparelSelect.split("-");
    setCurrentApparelSelect(currentApparelSelectSplit[0]+"-"+color+"-"+currentApparelSelectSplit[2]);
  }

  const changeTechColor = (color) => {
    let currentTechSelectSplit = ""
    currentTechSelectSplit = currentTechnologySelect.split("-");
    setCurrentTechnologySelect(currentTechSelectSplit[0]+"-"+color);
  }

  useEffect(() => {
    const currentAvatarBgFilter = bgState[bgStylePropertyType].filter(b => b.id === currentBgStyleSelect);
    setCurrentAvatarBg(currentAvatarBgFilter[0]);
  }, [currentBgStyleSelect]);

  useEffect(() => {
    const currentAvatarFurStyleFilter = furStyleState[furStylePropertyType].filter(b => b.id === currentFurStyleSelect);
    setCurrentAvatarFurStyle(currentAvatarFurStyleFilter[0]);
  }, [currentFurStyleSelect]);

  useEffect(() => {
    const currentAvatarSmileFilter = smileState[smilePropertyType].filter(b => b.id === currentSmileSelect);
    setCurrentAvatarSmile(currentAvatarSmileFilter[0]);
  }, [currentSmileSelect]);

  useEffect(() => {
    const currentAvatarHairFilter = hairState[hairColorType].filter(b => b.id === currentHairSelect);
    setCurrentAvatarHair(currentAvatarHairFilter[0]);
  }, [currentHairSelect]);

  useEffect(() => {
    const currentAvatarApparelFilter = apparelState[apparelColorType].filter(b => b.id === currentApparelSelect);
    setCurrentAvatarApparel(currentAvatarApparelFilter[0]);
  }, [currentApparelSelect]);

  useEffect(() => {
    const currentAvatarAccessoriesFilter = accessoriesState[accessoriesPropertyType].filter(b => b.id === currentAccessoriesSelect);
    setCurrentAvatarAccessories(currentAvatarAccessoriesFilter[0]);
  }, [currentAccessoriesSelect]);

  useEffect(() => {
    const currentTechnologyFilter = technologyState[technologyPropertyType].filter(b => b.id === currentTechnologySelect);
    setCurrentTechnology(currentTechnologyFilter[0]);
  }, [currentTechnologySelect]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchHasConId = searchParams.get("hasConId");
    const registrationName = searchParams.get("name");
    if (searchHasConId && searchHasConId !== "") {
      setHasConId(searchHasConId);
      if(registrationName !== "" && registrationName !== undefined) {
        setRegName(registrationName);
      }
    } else {
      window.location.href = "/events/hasura-con-2022/";
    }
  }, [location.search]);

  const downloadFinal = (isShare, shareTo) => {
    const imagesObj = [ currentAvatarBg, currentAvatarFurStyle, currentAvatarHair, currentAvatarSmile, currentAvatarApparel, currentAvatarAccessories, currentTechnology, currentAvatarBadge];
    const finalRenderArray = [];
    imagesObj.forEach(a => {

      if(a != null && a.avatarImg != undefined) {
        finalRenderArray.push(a.avatarImg);
      }
    })
    const options = {
      quality: 1.2,
      format: 'image/jpeg',
    }
    mergeImages(finalRenderArray, options)
    .then(b64 => {
        if(isShare === true && isShare !== undefined) {
          shareSocial(b64, shareTo);
        } else {
          const link = document.createElement('a')
          link.download = hasConId+'.jpeg';
          link.href = b64;
          link.click();
        }
    });
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
    }

  const copyText = async() => {
    try {
        notify.show("Generating a unique link to your avatar...", "success", 3000);
        const share = `https://hasuracon-avatar.hasura.io/?sr=reg&hasConId=${hasConId}`;
        navigator.clipboard.writeText(share);
        await downloadFinal(true, "copy");
    } catch (err) {
      console.log(err);
    }
  };

  const shareSocial = (image,shareTo) => {
    if (hasConId != undefined) {
      notify.show("Generating a unique link to your avatar...", "success", 3000);
      const file = dataURLtoFile(image, hasConId);
      const data = new FormData();
      data.append("file", file, file.name);
      axios({
        url: hasuraCon22UploadUrl,
        method: "post",
        data: data,
        responseType: "json",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(response => {
          if (response.status === 200) {
            const shareLink = `https://hasuracon-avatar.hasura.io/?sr%3DReg%26hasConId%3D${hasConId}`;
            const ta = document.createElement("a");
            if(!isSafari) {
              ta.target = "_blank";
            }
            ta.rel = "noopener noreferrer";
            if(shareTo === 'twitter') {
              ta.href = "https://twitter.com/intent/tweet?&text=I'm headed to HasuraCon'22 by @HasuraHQ! Register below and join me! Also, check out my custom Hasura avatar 😍 &hashtags=HasuraCon,GraphQL4everyone&url="+shareLink;
              ta.click();
            }
            if(shareTo === 'linkedin') {
              ta.href =`http://www.linkedin.com/shareArticle?mini=true&url=${shareLink}&title=GraphQL4everyone&source=https://hasura.io/events/hasura-con-2022/`;
              ta.click();
            }
            if(shareTo === 'copy') {
              notify.show("Copied link to your avatar!", "success", 3000);
            }
          } else {
            console.log("Sorry we couldn't generate Twitter Share now, try again later");
          }
        })
        .catch(err => {
          notify.show("Sorry, we can't generate a dynamic link to your avatar now! Show us support by downloading the avatar and sharing it directly :-)", "warning", 10000);
          console.error("err", err.response);
        });
    }
  };

  const bgWrapper = bgState[bgStylePropertyType].map((bg, index) => (
    <div key={index} className={"radiusRectangle" + ((currentBgStyleSelect === bg.id) ? " active" : "")}
      onClick={() => showAvatarBgStyleImg(bg.id)}>
      <img src={bg.imgSrc} alt="icons" />
    </div>
  ));

  const furStyleWrapper = furStyleState[furStylePropertyType].map((furStyle, index) => (
    <div key={index} style={{backgroundColor: furStyle.bgColor}} className={"radiusRectangle" + ((currentFurStyleSelect === furStyle.id) ? " active" : "")}
      onClick={() => showAvatarFurStyleImg(furStyle.id)}>
    </div>
  ));

  const smileWrapper = smileState[smilePropertyType].map((smile, index) => (
    <div key={index} className={"radiusRectangle" + ((currentSmileSelect === smile.id) ? " active" : "")}
      onClick={() => showAvatarSmileImg(smile.id)}>
      <img src={smile.imgSrc} alt="icons" />
    </div>
  ));

  const hairWrapper = hairState[hairColorType].map((hair, index) => (
    <div key={index} className={"radiusRectangle" + ((currentHairSelect === hair.id) ? " active" : "")}
      onClick={() => showAvatarHairImg(hair.id)}>
      <img src={hair.imgSrc} alt="icons" />
    </div>
  ));

  const apparelWrapper = apparelState[apparelColorType].map((apparel, index) => (
    <div key={index} className={"radiusRectangle" + ((currentApparelSelect === apparel.id) ? " active" : "")}
      onClick={() => showAvatarApparelImg(apparel.id)}>
      <img src={apparel.imgSrc} alt="icons" />
    </div>
  ));

  const accessoriesWrapper = accessoriesState[accessoriesPropertyType].map((accessories, index) => (
    <div key={index} className={"radiusRectangle" + ((currentAccessoriesSelect === accessories.id) ? " active" : "")}
      onClick={() => showAvatarAccessoriesImg(accessories.id)}>
      <img src={accessories.imgSrc} alt="icons" />
    </div>
  ));

  const technologyWrapper = technologyState[technologyPropertyType].map((technology, index) => (
    <div key={index} className={"radiusRectangle technology" + ((currentTechnologySelect === technology.id) ? " active" : "")}
      onClick={() => showTechnologyImg(technology.id)}>
      <img src={technology.thumbImg} alt="icons" />
    </div>
  ));

  return (
    <StyledSectionWrapperBg>
      <Notifications />
      <StyledAvatarHeroBanner>
        <div className="pl4 pr4 greyBgColorMobile mobileShow">
          <div className="hasuraConBrand mb100">
            <Link to="/events/hasura-con-2022/" onClick={scrollToTop}>
              <img src={hasuraConBrand} alt="Hasura Con Brand" />
            </Link>
            <ul className="hasuraConHeaderNav">
              <li>
                <a href="https://hasura.io/events/hasura-con-2022/#talks">
                  <StyledButton className="avatarNavBtn" variant="grey_90">
                    See Schedule
                  </StyledButton>
                </a>
              </li>
            </ul>
          </div>
          <StyledCreateAvatarWrapper>
            <div className="avatarCreateImg">
              {currentAvatarBg ? (
                <img
                  loading="eager"
                  className="avatarPos"
                  srcSet={currentAvatarBg?.avatarImg}
                  alt="Avatar"
                />
              ) : null}
              <img loading="eager" srcSet={currentAvatarFurStyle.avatarImg} alt="Avatar" />
              {currentAvatarSmile ? (
                <img
                  loading="eager"
                  className="avatarPos"
                  srcSet={currentAvatarSmile?.avatarImg}
                  alt="Avatar"
                />
              ) : null}
              {currentAvatarApparel ? (
                <img
                  loading="eager"
                  className="avatarPos"
                  srcSet={currentAvatarApparel?.avatarImg}
                  alt="Avatar"
                />
              ) : null}
              {currentAvatarHair ? (
                <img
                  loading="eager"
                  className="avatarPos zIndexOne"
                  srcSet={currentAvatarHair?.avatarImg}
                  alt="Avatar"
                />
              ) : null}
              {currentAvatarAccessories ? (
                <img
                  loading="eager"
                  className="avatarPos zIndexOne"
                  srcSet={currentAvatarAccessories?.avatarImg}
                  alt="Avatar"
                />
              ) : null}
              {currentTechnology ? (
                <img
                  loading="eager"
                  className="avatarPos zIndexOne"
                  srcSet={currentTechnology?.avatarImg}
                  alt="Avatar"
                />
              ) : null}
              {currentAvatarBadge ? (
                <img
                  loading="eager"
                  className="avatarPos badgePos"
                  srcSet={currentAvatarBadge?.avatarImg}
                  alt="Avatar"
                />
              ) : null}
              {/*
              <div className="stampWrapper">
                <img className="attandingImg" src={attending} alt="Attending Logo" />
                <StyledDescTag variant="white" fontWeight="font_600">
                  #graphql4everyone
                </StyledDescTag>
              </div>
              */}
            </div>
          </StyledCreateAvatarWrapper>
        </div>
        <div className="pl4">
          <div className="hasuraConBrand mb100 mobileHide">
            <Link to="/events/hasura-con-2022/" onClick={scrollToTop}>
              <img src={hasuraConBrand} alt="Hasura Con Brand" />
            </Link>
          </div>
          <div className="mW650">
            <StyledCreateAvatarWrapper>
              <div className="mobileMarAuto">
                <StyledDesc2 variant="white">
                  <ul className="avatarCreateTab smHide">
                    {/* eslint-disable-next-line */}
                    <li
                      className={avatarSelectState === "FurStyle" ? "active" : ""}
                      onClick={() => setAvatarSelectState("FurStyle")}
                    >
                      Fur style
                    </li>
                    {/* eslint-disable-next-line */}
                    <li
                      className={avatarSelectState === "BgStyle" ? "active" : ""}
                      onClick={() => setAvatarSelectState("BgStyle")}
                    >
                      BG style
                    </li>
                    {/* eslint-disable-next-line */}
                    <li
                      className={avatarSelectState === "Hair" ? "active" : ""}
                      onClick={() => setAvatarSelectState("Hair")}
                    >
                      Hair
                    </li>
                    {/* eslint-disable-next-line */}
                    <li
                      className={avatarSelectState === "Smile" ? "active" : ""}
                      onClick={() => setAvatarSelectState("Smile")}
                    >
                      Smile
                    </li>
                    {/* eslint-disable-next-line */}
                    <li
                      className={avatarSelectState === "Apparel" ? "active" : ""}
                      onClick={() => setAvatarSelectState("Apparel")}
                    >
                      Apparel
                    </li>
                    {/* eslint-disable-next-line */}
                    <li
                      className={avatarSelectState === "Accessories" ? "active" : ""}
                      onClick={() => setAvatarSelectState("Accessories")}
                    >
                      Accessories
                    </li>
                    {/* eslint-disable-next-line */}
                    <li
                      className={avatarSelectState === "Technology" ? "active" : ""}
                      onClick={() => setAvatarSelectState("Technology")}
                    >
                      Technology
                    </li>
                  </ul>
                  <div className="smShow">
                    <Slider className="carouselWrapper" {...settings}>
                      <div css={textCenter}>
                        {/* eslint-disable-next-line */}
                        <div
                          className={
                            "carouselTab" + (avatarSelectState === "FurStyle" ? " active" : "")
                          }
                          onClick={() => setAvatarSelectState("FurStyle")}
                        >
                          Fur style
                        </div>
                      </div>
                      <div css={textCenter}>
                        {/* eslint-disable-next-line */}
                        <div
                          className={
                            "carouselTab" + (avatarSelectState === "BgStyle" ? " active" : "")
                          }
                          onClick={() => setAvatarSelectState("BgStyle")}
                        >
                          BG style
                        </div>
                      </div>
                      <div css={textCenter}>
                        {/* eslint-disable-next-line */}
                        <div
                          className={
                            "carouselTab" + (avatarSelectState === "Hair" ? " active" : "")
                          }
                          onClick={() => setAvatarSelectState("Hair")}
                        >
                          Hair
                        </div>
                      </div>
                      <div css={textCenter}>
                        {/* eslint-disable-next-line */}
                        <div
                          className={
                            "carouselTab" + (avatarSelectState === "Smile" ? " active" : "")
                          }
                          onClick={() => setAvatarSelectState("Smile")}
                        >
                          Smile
                        </div>
                      </div>
                      <div css={textCenter}>
                        {/* eslint-disable-next-line */}
                        <div
                          className={
                            "carouselTab" + (avatarSelectState === "Apparel" ? " active" : "")
                          }
                          onClick={() => setAvatarSelectState("Apparel")}
                        >
                          Apparel
                        </div>
                      </div>
                      <div css={textCenter}>
                        {/* eslint-disable-next-line */}
                        <div
                          className={
                            "carouselTab" + (avatarSelectState === "Accessories" ? " active" : "")
                          }
                          onClick={() => setAvatarSelectState("Accessories")}
                        >
                          Accessories
                        </div>
                      </div>
                      <div css={textCenter}>
                        {/* eslint-disable-next-line */}
                        <div
                          className={
                            "carouselTab" + (avatarSelectState === "Technology" ? " active" : "")
                          }
                          onClick={() => setAvatarSelectState("Technology")}
                        >
                          Technology
                        </div>
                      </div>
                    </Slider>
                  </div>
                </StyledDesc2>

                {avatarSelectState === "BgStyle" ? (
                  <div className="propertyWrapper">{bgWrapper}</div>
                ) : null}
                {avatarSelectState === "FurStyle" ? (
                  <div className="propertyWrapper">{furStyleWrapper}</div>
                ) : null}

                {avatarSelectState === "Smile" ? (
                  <div className="propertyWrapper">{smileWrapper}</div>
                ) : null}
                {avatarSelectState === "Hair" ? (
                  <>
                    <div className="propertyTabWrapper">
                      <div
                        className={
                          "propertyTab blackBg" + (hairColorType === "black" ? " active" : "")
                        }
                        onClick={() => {
                          setHairColorType("black");
                          changeHairColor("black");
                        }}
                      ></div>
                      <div
                        className={
                          "propertyTab blondBg" + (hairColorType === "blond" ? " active" : "")
                        }
                        onClick={() => {
                          setHairColorType("blond");
                          changeHairColor("blond");
                        }}
                      ></div>
                      <div
                        className={
                          "propertyTab brownBg" + (hairColorType === "brown" ? " active" : "")
                        }
                        onClick={() => {
                          setHairColorType("brown");
                          changeHairColor("brown");
                        }}
                      ></div>
                      <div
                        className={
                          "propertyTab blueBg" + (hairColorType === "blue" ? " active" : "")
                        }
                        onClick={() => {
                          setHairColorType("blue");
                          changeHairColor("blue");
                        }}
                      ></div>
                    </div>
                    <div className="propertyWrapper">{hairWrapper}</div>
                  </>
                ) : null}
                {avatarSelectState === "Apparel" ? (
                  <>
                    <div className="propertyTabWrapper">
                      <div
                        className={
                          "propertyTab blackBg" + (apparelColorType === "black" ? " active" : "")
                        }
                        onClick={() => {
                          setApparelColorType("black");
                          changeApparelColor("black");
                          changeTechColor("light");
                          setTechnologyPropertyType("light");
                        }}
                      ></div>
                      <div
                        className={
                          "propertyTab greenBg" + (apparelColorType === "green" ? " active" : "")
                        }
                        onClick={() => {
                          setApparelColorType("green");
                          changeApparelColor("green");
                          changeTechColor("light");
                          setTechnologyPropertyType("light");
                        }}
                      ></div>
                      <div
                        className={
                          "propertyTab blueBg" + (apparelColorType === "blue" ? " active" : "")
                        }
                        onClick={() => {
                          setApparelColorType("blue");
                          changeApparelColor("blue");
                          changeTechColor("light");
                          setTechnologyPropertyType("light");
                        }}
                      ></div>
                      <div
                        className={
                          "propertyTab whiteBg" + (apparelColorType === "white" ? " active" : "")
                        }
                        onClick={() => {
                          setApparelColorType("white");
                          changeApparelColor("white");
                          changeTechColor("dark");
                          setTechnologyPropertyType("dark");
                        }}
                      ></div>
                      <div
                        className={
                          "propertyTab greyBg" + (apparelColorType === "grey" ? " active" : "")
                        }
                        onClick={() => {
                          setApparelColorType("grey");
                          changeApparelColor("grey");
                          changeTechColor("light");
                          setTechnologyPropertyType("light");
                        }}
                      ></div>
                      <div
                        className={
                          "propertyTab orangeBg" + (apparelColorType === "orange" ? " active" : "")
                        }
                        onClick={() => {
                          setApparelColorType("orange");
                          changeApparelColor("orange");
                          changeTechColor("dark");
                          setTechnologyPropertyType("dark");
                        }}
                      ></div>
                      <div
                        className="propertyTab"
                        onClick={() => {
                          setApparelColorType("pattern");
                          changeApparelColor("pattern");
                          changeTechColor("light");
                          setTechnologyPropertyType("light");
                        }}
                      >
                        <img className={"patternIcon" + ((apparelColorType) === "pattern" ? " patternActive" : "")} src={patternIcon} alt="Pattern" />
                      </div>

                    </div>
                    <div className="propertyWrapper">{apparelWrapper}</div>
                  </>
                ) : null}

                {avatarSelectState === "Accessories" ? (
                  <div className="propertyWrapper">{accessoriesWrapper}</div>
                ) : null}

                {avatarSelectState === "Technology" ? (
                  <div className="propertyWrapper">{technologyWrapper}</div>
                ) : null}
              </div>
            </StyledCreateAvatarWrapper>
          </div>
        </div>
        <div className="pr4 greyBgColor">
          <ul className="hasuraConHeaderNav mb100 mobileHide">
            <li>
              <a href="https://hasura.io/events/hasura-con-2022/#talks">
                <StyledButton className="avatarNavBtn" variant="grey_90">
                  See Schedule
                </StyledButton>
              </a>
            </li>
          </ul>
          <StyledCreateAvatarWrapper className="positionSticky">
            <div>
              <div className="mWidth386">
                <div className="avatarCreateImg mobileHide">
                  {currentAvatarBg ? (
                    <img
                      loading="eager"
                      className="avatarPos"
                      srcSet={currentAvatarBg?.avatarImg}
                      alt="Avatar"
                    />
                  ) : null}
                  <img loading="eager" srcSet={currentAvatarFurStyle.avatarImg} alt="Avatar" />
                  {currentAvatarSmile ? (
                    <img
                      loading="eager"
                      className="avatarPos"
                      srcSet={currentAvatarSmile?.avatarImg}
                      alt="Avatar"
                    />
                  ) : null}
                  {currentAvatarApparel ? (
                    <img
                      loading="eager"
                      className="avatarPos"
                      srcSet={currentAvatarApparel?.avatarImg}
                      alt="Avatar"
                    />
                  ) : null}
                  {currentAvatarHair ? (
                    <img
                      loading="eager"
                      className="avatarPos zIndexOne"
                      srcSet={currentAvatarHair?.avatarImg}
                      alt="Avatar"
                    />
                  ) : null}
                  {currentAvatarAccessories ? (
                    <img
                      loading="eager"
                      className="avatarPos zIndexOne"
                      srcSet={currentAvatarAccessories?.avatarImg}
                      alt="Avatar"
                    />
                  ) : null}
                  {currentTechnology ? (
                    <img
                      loading="eager"
                      className="avatarPos zIndexOne"
                      srcSet={currentTechnology?.avatarImg}
                      alt="Avatar"
                    />
                  ) : null}
                  {currentAvatarBadge ? (
                    <img
                      loading="eager"
                      className="avatarPos badgePos"
                      srcSet={currentAvatarBadge?.avatarImg}
                      alt="Avatar"
                    />
                  ) : null}
                  {/*
                  <div className="stampWrapper">
                    <img className="attandingImg" src={attending} alt="Attending Logo" />
                    <StyledDescTag variant="white" fontWeight="font_600">
                      #graphql4everyone
                    </StyledDescTag>
                  </div>
                  */}
                </div>
                <div className="buttonWrapper">
                  <button className="shareBtn twitterBtn" onClick={() => downloadFinal(true, "twitter")}>
                    <img src={twitterIcon} alt="Download" />
                  </button>
                  <button className="shareBtn linkedInBtn" onClick={() => downloadFinal(true, "linkedin")}>
                    <img src={linkedinIcon} alt="Download" />
                  </button>
                  <button className="shareBtn downloadBtn" onClick={() => downloadFinal()}>
                    <img src={downloadIcon} alt="Download" />
                  </button>
                  <button className="shareBtn copyBtn" onClick={() => copyText()}>
                    <img src={contentIcon} alt="Copy" />
                  </button>
                  {/*
                  <StyledButton className="mBtn" variant="blue" onClick={() => downloadFinal()}>
                    Download
                    <Icon className="rightBtnIcon" variant="arrow_down" color="white" size="sm" />
                  </StyledButton>
                  <StyledButton
                    className="mBtn"
                    variant="blue"
                    onClick={() => downloadFinal(true, "twitter")}
                  >
                    Twitter
                    <Icon className="rightBtnIcon" variant="arrow_right" color="white" size="sm" />
                  </StyledButton>
                  <StyledButton
                    className="mBtn"
                    variant="blue"
                    onClick={() => downloadFinal(true, "linkedin")}
                  >
                    Linkedin
                    <Icon className="rightBtnIcon" variant="arrow_right" color="white" size="sm" />
                  </StyledButton>
                  */}
                  {/* <CopyToClipboard text="https://hasura.io/events/hasura-con-2020/" onCopy={onCopy}>
                    <div className="socialShare">{renderCopyIcon()}</div>
                  </CopyToClipboard> */}
                  {/* <div>
                    <button onClick= {() => downloadFinal(true, "copy")}>Copy</button>
                    {copySuccess}
                  </div> */}
                </div>
                <div className="footerAvatar">
                  <StyledDesc3 variant="white" className="mAlignCenter" fontWeight="font_500"> © {new Date().getFullYear()} Hasura Inc. All rights reserved</StyledDesc3>
                  <div className="hasuraPowerdBrand">
                    <img src={hasuraPoweredBrandWhite} alt="Hasura Con Brand" />
                  </div>
                </div>
              </div>
            </div>
          </StyledCreateAvatarWrapper>
        </div>
      </StyledAvatarHeroBanner>
    </StyledSectionWrapperBg>
  );
};


export default CreateAvatar;
