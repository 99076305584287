import hasConBadge from "./avatarimages/attending-badge.png";

import avatarFur1 from "./avatarimages/avatar-fur-1.png";
import avatarFur2 from "./avatarimages/avatar-fur-2.png";
import avatarFur3 from "./avatarimages/avatar-fur-3.png";
import avatarFur4 from "./avatarimages/avatar-fur-4.png";
import avatarFur5 from "./avatarimages/avatar-fur-5.png";
import avatarFur6 from "./avatarimages/avatar-fur-6.png";
import avatarFur7 from "./avatarimages/avatar-fur-7.png";

import thumbHairBlack1 from "./avatarimages/thumb-hair-black-1.png";
import thumbHairBlack2 from "./avatarimages/thumb-hair-black-2.png";
import thumbHairBlack3 from "./avatarimages/thumb-hair-black-3.png";
import thumbHairBlack4 from "./avatarimages/thumb-hair-black-4.png";
import thumbHairBlack5 from "./avatarimages/thumb-hair-black-5.png";
import thumbHairBlack6 from "./avatarimages/thumb-hair-black-6.png";
import thumbHairBlack7 from "./avatarimages/thumb-hair-black-7.png";
import thumbHairBlack8 from "./avatarimages/thumb-hair-black-8.png";
import thumbHairBlack9 from "./avatarimages/thumb-hair-black-9.png";
import thumbHairBlack10 from "./avatarimages/thumb-hair-black-10.png";
import thumbHairBlack11 from "./avatarimages/thumb-hair-black-11.png";
import thumbHairBlack12 from "./avatarimages/thumb-hair-black-12.png";
import thumbHairBlack13 from "./avatarimages/thumb-hair-black-13.png";
import thumbHairBlack14 from "./avatarimages/thumb-hair-black-14.png";
import thumbHairBlack15 from "./avatarimages/thumb-hair-black-15.png";
import thumbHairBlack16 from "./avatarimages/thumb-hair-black-16.png";

import avatarHairBlack1 from "./avatarimages/avatar-hair-black-1.png";
import avatarHairBlack2 from "./avatarimages/avatar-hair-black-2.png";
import avatarHairBlack3 from "./avatarimages/avatar-hair-black-3.png";
import avatarHairBlack4 from "./avatarimages/avatar-hair-black-4.png";
import avatarHairBlack5 from "./avatarimages/avatar-hair-black-5.png";
import avatarHairBlack6 from "./avatarimages/avatar-hair-black-6.png";
import avatarHairBlack7 from "./avatarimages/avatar-hair-black-7.png";
import avatarHairBlack8 from "./avatarimages/avatar-hair-black-8.png";
import avatarHairBlack9 from "./avatarimages/avatar-hair-black-9.png";
import avatarHairBlack10 from "./avatarimages/avatar-hair-black-10.png";
import avatarHairBlack11 from "./avatarimages/avatar-hair-black-11.png";
import avatarHairBlack12 from "./avatarimages/avatar-hair-black-12.png";
import avatarHairBlack13 from "./avatarimages/avatar-hair-black-13.png";
import avatarHairBlack14 from "./avatarimages/avatar-hair-black-14.png";
import avatarHairBlack15 from "./avatarimages/avatar-hair-black-15.png";
import avatarHairBlack16 from "./avatarimages/avatar-hair-black-16.png";

import avatarHairBlond1 from "./avatarimages/avatar-hair-blond-1.png";
import avatarHairBlond2 from "./avatarimages/avatar-hair-blond-2.png";
import avatarHairBlond3 from "./avatarimages/avatar-hair-blond-3.png";
import avatarHairBlond4 from "./avatarimages/avatar-hair-blond-4.png";
import avatarHairBlond5 from "./avatarimages/avatar-hair-blond-5.png";
import avatarHairBlond6 from "./avatarimages/avatar-hair-blond-6.png";
import avatarHairBlond7 from "./avatarimages/avatar-hair-blond-7.png";
import avatarHairBlond8 from "./avatarimages/avatar-hair-blond-8.png";
import avatarHairBlond9 from "./avatarimages/avatar-hair-blond-9.png";
import avatarHairBlond10 from "./avatarimages/avatar-hair-blond-10.png";
import avatarHairBlond11 from "./avatarimages/avatar-hair-blond-11.png";
import avatarHairBlond12 from "./avatarimages/avatar-hair-blond-12.png";
import avatarHairBlond13 from "./avatarimages/avatar-hair-blond-13.png";
import avatarHairBlond14 from "./avatarimages/avatar-hair-blond-14.png";
import avatarHairBlond15 from "./avatarimages/avatar-hair-blond-15.png";
import avatarHairBlond16 from "./avatarimages/avatar-hair-blond-16.png";

import avatarHairBrown1 from "./avatarimages/avatar-hair-brown-1.png";
import avatarHairBrown2 from "./avatarimages/avatar-hair-brown-2.png";
import avatarHairBrown3 from "./avatarimages/avatar-hair-brown-3.png";
import avatarHairBrown4 from "./avatarimages/avatar-hair-brown-4.png";
import avatarHairBrown5 from "./avatarimages/avatar-hair-brown-5.png";
import avatarHairBrown6 from "./avatarimages/avatar-hair-brown-6.png";
import avatarHairBrown7 from "./avatarimages/avatar-hair-brown-7.png";
import avatarHairBrown8 from "./avatarimages/avatar-hair-brown-8.png";
import avatarHairBrown9 from "./avatarimages/avatar-hair-brown-9.png";
import avatarHairBrown10 from "./avatarimages/avatar-hair-brown-10.png";
import avatarHairBrown11 from "./avatarimages/avatar-hair-brown-11.png";
import avatarHairBrown12 from "./avatarimages/avatar-hair-brown-12.png";
import avatarHairBrown13 from "./avatarimages/avatar-hair-brown-13.png";
import avatarHairBrown14 from "./avatarimages/avatar-hair-brown-14.png";
import avatarHairBrown15 from "./avatarimages/avatar-hair-brown-15.png";
import avatarHairBrown16 from "./avatarimages/avatar-hair-brown-16.png";

import avatarHairBlue1 from "./avatarimages/avatar-hair-blue-1.png";
import avatarHairBlue2 from "./avatarimages/avatar-hair-blue-2.png";
import avatarHairBlue3 from "./avatarimages/avatar-hair-blue-3.png";
import avatarHairBlue4 from "./avatarimages/avatar-hair-blue-4.png";
import avatarHairBlue5 from "./avatarimages/avatar-hair-blue-5.png";
import avatarHairBlue6 from "./avatarimages/avatar-hair-blue-6.png";
import avatarHairBlue7 from "./avatarimages/avatar-hair-blue-7.png";
import avatarHairBlue8 from "./avatarimages/avatar-hair-blue-8.png";
import avatarHairBlue9 from "./avatarimages/avatar-hair-blue-9.png";
import avatarHairBlue10 from "./avatarimages/avatar-hair-blue-10.png";
import avatarHairBlue11 from "./avatarimages/avatar-hair-blue-11.png";
import avatarHairBlue12 from "./avatarimages/avatar-hair-blue-12.png";
import avatarHairBlue13 from "./avatarimages/avatar-hair-blue-13.png";
import avatarHairBlue14 from "./avatarimages/avatar-hair-blue-14.png";
import avatarHairBlue15 from "./avatarimages/avatar-hair-blue-15.png";
import avatarHairBlue16 from "./avatarimages/avatar-hair-blue-16.png";

import thumbSmile1 from "./avatarimages/thumb-smile-1.jpg";
import thumbSmile2 from "./avatarimages/thumb-smile-2.jpg";
import thumbSmile3 from "./avatarimages/thumb-smile-3.jpg";
import thumbSmile4 from "./avatarimages/thumb-smile-4.jpg";
import thumbSmile5 from "./avatarimages/thumb-smile-5.jpg";
import thumbSmile6 from "./avatarimages/thumb-smile-6.jpg";

import avatarSmile1 from "./avatarimages/avatar-smile-1.png";
import avatarSmile2 from "./avatarimages/avatar-smile-2.png";
import avatarSmile3 from "./avatarimages/avatar-smile-3.png";
import avatarSmile4 from "./avatarimages/avatar-smile-4.png";
import avatarSmile5 from "./avatarimages/avatar-smile-5.png";
import avatarSmile6 from "./avatarimages/avatar-smile-6.png";


import avatarApparelBlack1 from "./avatarimages/avatar-apparel-black-1.png";
import avatarApparelBlack2 from "./avatarimages/avatar-apparel-black-2.png";
import avatarApparelBlack3 from "./avatarimages/avatar-apparel-black-3.png";
import avatarApparelBlack4 from "./avatarimages/avatar-apparel-black-4.png";

import thumbApparelBlack1 from "./avatarimages/thumb-apparel-black-1.png";
import thumbApparelBlack2 from "./avatarimages/thumb-apparel-black-2.png";
import thumbApparelBlack3 from "./avatarimages/thumb-apparel-black-3.png";
import thumbApparelBlack4 from "./avatarimages/thumb-apparel-black-4.png";

import thumbApparelPattern1 from "./avatarimages/thumb-apparel-pattern-1.png";
import thumbApparelPattern2 from "./avatarimages/thumb-apparel-pattern-2.png";
import thumbApparelPattern3 from "./avatarimages/thumb-apparel-pattern-3.png";
import thumbApparelPattern4 from "./avatarimages/thumb-apparel-pattern-4.png";
import thumbApparelPattern5 from "./avatarimages/thumb-apparel-pattern-5.png";
import thumbApparelPattern6 from "./avatarimages/thumb-apparel-pattern-6.png";

import avatarApparelGreen1 from "./avatarimages/avatar-apparel-green-1.png";
import avatarApparelGreen2 from "./avatarimages/avatar-apparel-green-2.png";
import avatarApparelGreen3 from "./avatarimages/avatar-apparel-green-3.png";
import avatarApparelGreen4 from "./avatarimages/avatar-apparel-green-4.png";

import avatarApparelBlue1 from "./avatarimages/avatar-apparel-blue-1.png";
import avatarApparelBlue2 from "./avatarimages/avatar-apparel-blue-2.png";
import avatarApparelBlue3 from "./avatarimages/avatar-apparel-blue-3.png";
import avatarApparelBlue4 from "./avatarimages/avatar-apparel-blue-4.png";

import avatarApparelWhite1 from "./avatarimages/avatar-apparel-white-1.png";
import avatarApparelWhite2 from "./avatarimages/avatar-apparel-white-2.png";
import avatarApparelWhite3 from "./avatarimages/avatar-apparel-white-3.png";
import avatarApparelWhite4 from "./avatarimages/avatar-apparel-white-4.png";


import avatarApparelGrey1 from "./avatarimages/avatar-apparel-grey-1.png";
import avatarApparelGrey2 from "./avatarimages/avatar-apparel-grey-2.png";
import avatarApparelGrey3 from "./avatarimages/avatar-apparel-grey-3.png";
import avatarApparelGrey4 from "./avatarimages/avatar-apparel-grey-4.png";

import avatarApparelOrange1 from "./avatarimages/avatar-apparel-orange-1.png";
import avatarApparelOrange2 from "./avatarimages/avatar-apparel-orange-2.png";
import avatarApparelOrange3 from "./avatarimages/avatar-apparel-orange-3.png";
import avatarApparelOrange4 from "./avatarimages/avatar-apparel-orange-4.png";

import avatarApparelPattern1 from "./avatarimages/avatar-apparel-pattern-1.png";
import avatarApparelPattern2 from "./avatarimages/avatar-apparel-pattern-2.png";
import avatarApparelPattern3 from "./avatarimages/avatar-apparel-pattern-3.png";
import avatarApparelPattern4 from "./avatarimages/avatar-apparel-pattern-4.png";
import avatarApparelPattern5 from "./avatarimages/avatar-apparel-pattern-5.png";
import avatarApparelPattern6 from "./avatarimages/avatar-apparel-pattern-6.png";

import avatarAccessories1 from "./avatarimages/avatar-accessories-1.png";
import avatarAccessories2 from "./avatarimages/avatar-accessories-2.png";
import avatarAccessories3 from "./avatarimages/avatar-accessories-3.png";
import avatarAccessories4 from "./avatarimages/avatar-accessories-4.png";
import avatarAccessories5 from "./avatarimages/avatar-accessories-5.png";
import avatarAccessories6 from "./avatarimages/avatar-accessories-6.png";
import avatarAccessories7 from "./avatarimages/avatar-accessories-7.png";
import avatarAccessories8 from "./avatarimages/avatar-accessories-8.png";
import avatarAccessories9 from "./avatarimages/avatar-accessories-9.png";
import avatarAccessories10 from "./avatarimages/avatar-accessories-10.png";
import avatarAccessories11 from "./avatarimages/avatar-accessories-11.png";
// import avatarAccessories12 from "./avatarimages/avatar-accessories-12.png";

import thumbAccessories1 from "./avatarimages/thumb-accessories-1.png";
import thumbAccessories2 from "./avatarimages/thumb-accessories-2.png";
import thumbAccessories3 from "./avatarimages/thumb-accessories-3.png";
import thumbAccessories4 from "./avatarimages/thumb-accessories-4.png";
import thumbAccessories5 from "./avatarimages/thumb-accessories-5.png";
import thumbAccessories6 from "./avatarimages/thumb-accessories-6.png";
import thumbAccessories7 from "./avatarimages/thumb-accessories-7.png";
import thumbAccessories8 from "./avatarimages/thumb-accessories-8.png";
import thumbAccessories9 from "./avatarimages/thumb-accessories-9.png";
import thumbAccessories10 from "./avatarimages/thumb-accessories-10.png";
import thumbAccessories11 from "./avatarimages/thumb-accessories-11.png";
// import thumbAccessories12 from "./avatarimages/thumb-accessories-12.png";

import csharpWhite from "./avatarimages/csharp-light.png";
// import netWhite from "./avatarimages/net-light.png";
import javaWhite from "./avatarimages/java-light.png";
import mongoWhite from "./avatarimages/mongo-light.png";
import kotlinWhite from "./avatarimages/kotlin-light.png";
import mysqlWhite from "./avatarimages/mysql-light.png";
import postgresWhite from "./avatarimages/postgres-light.png";
import flutterWhite from "./avatarimages/flutter-light.png";
import reactWhite from "./avatarimages/react-light.png";
import nextjsWhite from "./avatarimages/nextjs-light.png";
import vueWhite from "./avatarimages/vue-light.png";
import typescriptWhite from "./avatarimages/typescript-light.png";
import haskellWhite from "./avatarimages/haskell-light.png";
import swiftWhite from "./avatarimages/swift-light.png";
import hasuraWhite from "./avatarimages/hasura-light.png";
import goWhite from "./avatarimages/go-light.png";
import angularWhite from "./avatarimages/angular-light.png";
import gatsbyWhite from "./avatarimages/gatsby-light.png";

import csharpBlack from "./avatarimages/csharp-dark.png";
// import netBlack from "./avatarimages/net-dark.png";
import javaBlack from "./avatarimages/java-dark.png";
import mongoBlack from "./avatarimages/mongo-dark.png";
import kotlinBlack from "./avatarimages/kotlin-dark.png";
import mysqlBlack from "./avatarimages/mysql-dark.png";
import postgresBlack from "./avatarimages/postgres-dark.png";
import flutterBlack from "./avatarimages/flutter-dark.png";
import reactBlack from "./avatarimages/react-dark.png";
import nextjsBlack from "./avatarimages/nextjs-dark.png";
import vueBlack from "./avatarimages/vue-dark.png";
import typescriptBlack from "./avatarimages/typescript-dark.png";
import haskellBlack from "./avatarimages/haskell-dark.png";
import swiftBlack from "./avatarimages/swift-dark.png";
import hasuraBlack from "./avatarimages/hasura-dark.png";
import goBlack from "./avatarimages/go-dark.png";
import angularBlack from "./avatarimages/angular-dark.png";
import gatsbyBlack from "./avatarimages/gatsby-dark.png";


import csharpThumb from "./avatarimages/csharp-white.png";
// import netThumb from "./avatarimages/net-white.png";
import javaThumb from "./avatarimages/java-white.png";
import mongoThumb from "./avatarimages/mongo-white.png";
import kotlinThumb from "./avatarimages/kotlin-white.png";
import mysqlThumb from "./avatarimages/mysql-white.png";
import postgresThumb from "./avatarimages/postgres-white.png";
import flutterThumb from "./avatarimages/flutter-white.png";
import reactThumb from "./avatarimages/react-white.png";
import nextjsThumb from "./avatarimages/nextjs-white.png";
import vueThumb from "./avatarimages/vue-white.png";
import typescriptThumb from "./avatarimages/typescript-white.png";
import haskellThumb from "./avatarimages/haskell-white.png";
import swiftThumb from "./avatarimages/swift-white.png";
import hasuraThumb from "./avatarimages/hasura-white.png";
import goThumb from "./avatarimages/go-white.png";
import angularThumb from "./avatarimages/angular-white.png";
import gatsbyThumb from "./avatarimages/gatsby-white.png";

import avatarBg1 from "./avatarimages/avatar-bg-1.png";
import avatarBg2 from "./avatarimages/avatar-bg-2.png";
import avatarBg3 from "./avatarimages/avatar-bg-3.png";
import avatarBg4 from "./avatarimages/avatar-bg-4.png";
import avatarBg5 from "./avatarimages/avatar-bg-5.png";
import avatarBg6 from "./avatarimages/avatar-bg-6.png";
import avatarBg7 from "./avatarimages/avatar-bg-7.png";
import avatarBg8 from "./avatarimages/avatar-bg-8.png";
import avatarBg9 from "./avatarimages/avatar-bg-9.png";
import avatarBg10 from "./avatarimages/avatar-bg-10.png";
import avatarBg11 from "./avatarimages/avatar-bg-11.png";
import avatarBg12 from "./avatarimages/avatar-bg-12.png";
import avatarBg13 from "./avatarimages/avatar-bg-13.png";
import avatarBg14 from "./avatarimages/avatar-bg-14.png";
import avatarBg15 from "./avatarimages/avatar-bg-15.png";
import avatarBg16 from "./avatarimages/avatar-bg-16.png";
import avatarBg17 from "./avatarimages/avatar-bg-17.png";
import avatarBg18 from "./avatarimages/avatar-bg-18.png";
import avatarBg19 from "./avatarimages/avatar-bg-19.png";
import avatarBg20 from "./avatarimages/avatar-bg-20.png";
import avatarBg21 from "./avatarimages/avatar-bg-21.png";

const bgState = {
  property: [
    {
      id: "bg-21",
      imgSrc: avatarBg21,
      avatarImg: avatarBg21,
    },
    {
      id: "bg-1",
      imgSrc: avatarBg1,
      avatarImg: avatarBg1,
    },
    {
      id: "bg-2",
      imgSrc: avatarBg2,
      avatarImg: avatarBg2,
    },
    {
      id: "bg-3",
      imgSrc: avatarBg3,
      avatarImg: avatarBg3,
    },
    {
      id: "bg-4",
      imgSrc: avatarBg4,
      avatarImg: avatarBg4,
    },
    {
      id: "bg-5",
      imgSrc: avatarBg5,
      avatarImg: avatarBg5,
    },
    {
      id: "bg-6",
      imgSrc: avatarBg6,
      avatarImg: avatarBg6,
    },
    {
      id: "bg-7",
      imgSrc: avatarBg7,
      avatarImg: avatarBg7,
    },
    {
      id: "bg-8",
      imgSrc: avatarBg8,
      avatarImg: avatarBg8,
    },
    {
      id: "bg-9",
      imgSrc: avatarBg9,
      avatarImg: avatarBg9,
    },
    {
      id: "bg-10",
      imgSrc: avatarBg10,
      avatarImg: avatarBg10,
    },
    {
      id: "bg-11",
      imgSrc: avatarBg11,
      avatarImg: avatarBg11,
    },
    {
      id: "bg-12",
      imgSrc: avatarBg12,
      avatarImg: avatarBg12,
    },
    {
      id: "bg-13",
      imgSrc: avatarBg13,
      avatarImg: avatarBg13,
    },
    {
      id: "bg-14",
      imgSrc: avatarBg14,
      avatarImg: avatarBg14,
    },
    {
      id: "bg-15",
      imgSrc: avatarBg15,
      avatarImg: avatarBg15,
    },
    {
      id: "bg-16",
      imgSrc: avatarBg16,
      avatarImg: avatarBg16,
    },
    {
      id: "bg-17",
      imgSrc: avatarBg17,
      avatarImg: avatarBg17,
    },
    {
      id: "bg-18",
      imgSrc: avatarBg18,
      avatarImg: avatarBg18,
    },
    {
      id: "bg-19",
      imgSrc: avatarBg19,
      avatarImg: avatarBg19,
    },
    {
      id: "bg-20",
      imgSrc: avatarBg20,
      avatarImg: avatarBg20,
    },
  ],
}

const furStyleState = {
  property: [
    {
      id: "fur-1",
      bgColor: "#beddea",
      avatarImg: avatarFur1,
    },
    {
      id: "fur-2",
      bgColor: "#ffc960",
      avatarImg: avatarFur2,
    },
    {
      id: "fur-3",
      bgColor: "#1eb889",
      avatarImg: avatarFur3,
    },
    {
      id: "fur-4",
      bgColor: "#e484ad",
      avatarImg: avatarFur4,
    },
    {
      id: "fur-5",
      bgColor: "#ddddde",
      avatarImg: avatarFur5,
    },
    {
      id: "fur-6",
      bgColor: "#a076fa",
      avatarImg: avatarFur6,
    },
    {
      id: "fur-7",
      bgColor: "#91d3cc",
      avatarImg: avatarFur7,
    },
  ],
}

const smileState = {
  property: [
    {
      id: "smile-1",
      imgSrc: thumbSmile1,
      avatarImg: avatarSmile1,
    },
    {
      id: "smile-2",
      imgSrc: thumbSmile2,
      avatarImg: avatarSmile2,
    },
    {
      id: "smile-3",
      imgSrc: thumbSmile3,
      avatarImg: avatarSmile3,
    },
    {
      id: "smile-4",
      imgSrc: thumbSmile4,
      avatarImg: avatarSmile4,
    },
    {
      id: "smile-5",
      imgSrc: thumbSmile5,
      avatarImg: avatarSmile5,
    },
    {
      id: "smile-6",
      imgSrc: thumbSmile6,
      avatarImg: avatarSmile6,
    },
  ],
}

const hairState = {
  black: [
    {
      id: "hair-black-1",
      imgSrc: thumbHairBlack1,
      avatarImg: avatarHairBlack1,
    },
    {
      id: "hair-black-2",
      imgSrc: thumbHairBlack2,
      avatarImg: avatarHairBlack2,
    },
    {
      id: "hair-black-3",
      imgSrc: thumbHairBlack3,
      avatarImg: avatarHairBlack3,
    },
    {
      id: "hair-black-4",
      imgSrc: thumbHairBlack4,
      avatarImg: avatarHairBlack4,
    },
    {
      id: "hair-black-5",
      imgSrc: thumbHairBlack5,
      avatarImg: avatarHairBlack5,
    },
    {
      id: "hair-black-6",
      imgSrc: thumbHairBlack6,
      avatarImg: avatarHairBlack6,
    },
    {
      id: "hair-black-7",
      imgSrc: thumbHairBlack7,
      avatarImg: avatarHairBlack7,
    },
    {
      id: "hair-black-8",
      imgSrc: thumbHairBlack8,
      avatarImg: avatarHairBlack8,
    },
    {
      id: "hair-black-9",
      imgSrc: thumbHairBlack9,
      avatarImg: avatarHairBlack9,
    },
    {
      id: "hair-black-10",
      imgSrc: thumbHairBlack10,
      avatarImg: avatarHairBlack10,
    },
    {
      id: "hair-black-11",
      imgSrc: thumbHairBlack11,
      avatarImg: avatarHairBlack11,
    },
    {
      id: "hair-black-12",
      imgSrc: thumbHairBlack12,
      avatarImg: avatarHairBlack12,
    },
    {
      id: "hair-black-13",
      imgSrc: thumbHairBlack13,
      avatarImg: avatarHairBlack13,
    },
    {
      id: "hair-black-14",
      imgSrc: thumbHairBlack14,
      avatarImg: avatarHairBlack14,
    },
    {
      id: "hair-black-15",
      imgSrc: thumbHairBlack15,
      avatarImg: avatarHairBlack15,
    },
    {
      id: "hair-black-16",
      imgSrc: thumbHairBlack16,
      avatarImg: avatarHairBlack16,
    },
  ],
  blond: [
    {
      id: "hair-blond-1",
      imgSrc: thumbHairBlack1,
      avatarImg: avatarHairBlond1,
    },
    {
      id: "hair-blond-2",
      imgSrc: thumbHairBlack2,
      avatarImg: avatarHairBlond2,
    },
    {
      id: "hair-blond-3",
      imgSrc: thumbHairBlack3,
      avatarImg: avatarHairBlond3,
    },
    {
      id: "hair-blond-4",
      imgSrc: thumbHairBlack4,
      avatarImg: avatarHairBlond4,
    },
    {
      id: "hair-blond-5",
      imgSrc: thumbHairBlack5,
      avatarImg: avatarHairBlond5,
    },
    {
      id: "hair-blond-6",
      imgSrc: thumbHairBlack6,
      avatarImg: avatarHairBlond6,
    },
    {
      id: "hair-blond-7",
      imgSrc: thumbHairBlack7,
      avatarImg: avatarHairBlond7,
    },
    {
      id: "hair-blond-8",
      imgSrc: thumbHairBlack8,
      avatarImg: avatarHairBlond8,
    },
    {
      id: "hair-blond-9",
      imgSrc: thumbHairBlack9,
      avatarImg: avatarHairBlond9,
    },
    {
      id: "hair-blond-10",
      imgSrc: thumbHairBlack10,
      avatarImg: avatarHairBlond10,
    },
    {
      id: "hair-blond-11",
      imgSrc: thumbHairBlack11,
      avatarImg: avatarHairBlond11,
    },
    {
      id: "hair-blond-12",
      imgSrc: thumbHairBlack12,
      avatarImg: avatarHairBlond12,
    },
    {
      id: "hair-blond-13",
      imgSrc: thumbHairBlack13,
      avatarImg: avatarHairBlond13,
    },
    {
      id: "hair-blond-14",
      imgSrc: thumbHairBlack14,
      avatarImg: avatarHairBlond14,
    },
    {
      id: "hair-blond-15",
      imgSrc: thumbHairBlack15,
      avatarImg: avatarHairBlond15,
    },
    {
      id: "hair-blond-16",
      imgSrc: thumbHairBlack16,
      avatarImg: avatarHairBlond16,
    },
  ],
  brown: [
    {
      id: "hair-brown-1",
      imgSrc: thumbHairBlack1,
      avatarImg: avatarHairBrown1,
    },
    {
      id: "hair-brown-2",
      imgSrc: thumbHairBlack2,
      avatarImg: avatarHairBrown2,
    },
    {
      id: "hair-brown-3",
      imgSrc: thumbHairBlack3,
      avatarImg: avatarHairBrown3,
    },
    {
      id: "hair-brown-4",
      imgSrc: thumbHairBlack4,
      avatarImg: avatarHairBrown4,
    },
    {
      id: "hair-brown-5",
      imgSrc: thumbHairBlack5,
      avatarImg: avatarHairBrown5,
    },
    {
      id: "hair-brown-6",
      imgSrc: thumbHairBlack6,
      avatarImg: avatarHairBrown6,
    },
    {
      id: "hair-brown-7",
      imgSrc: thumbHairBlack7,
      avatarImg: avatarHairBrown7,
    },
    {
      id: "hair-brown-8",
      imgSrc: thumbHairBlack8,
      avatarImg: avatarHairBrown8,
    },
    {
      id: "hair-brown-9",
      imgSrc: thumbHairBlack9,
      avatarImg: avatarHairBrown9,
    },
    {
      id: "hair-brown-10",
      imgSrc: thumbHairBlack10,
      avatarImg: avatarHairBrown10,
    },
    {
      id: "hair-brown-11",
      imgSrc: thumbHairBlack11,
      avatarImg: avatarHairBrown11,
    },
    {
      id: "hair-brown-12",
      imgSrc: thumbHairBlack12,
      avatarImg: avatarHairBrown12,
    },
    {
      id: "hair-brown-13",
      imgSrc: thumbHairBlack13,
      avatarImg: avatarHairBrown13,
    },
    {
      id: "hair-brown-14",
      imgSrc: thumbHairBlack14,
      avatarImg: avatarHairBrown14,
    },
    {
      id: "hair-brown-15",
      imgSrc: thumbHairBlack15,
      avatarImg: avatarHairBrown15,
    },
    {
      id: "hair-brown-16",
      imgSrc: thumbHairBlack16,
      avatarImg: avatarHairBrown16,
    },
  ],
  blue: [
    {
      id: "hair-blue-1",
      imgSrc: thumbHairBlack1,
      avatarImg: avatarHairBlue1,
    },
    {
      id: "hair-blue-2",
      imgSrc: thumbHairBlack2,
      avatarImg: avatarHairBlue2,
    },
    {
      id: "hair-blue-3",
      imgSrc: thumbHairBlack3,
      avatarImg: avatarHairBlue3,
    },
    {
      id: "hair-blue-4",
      imgSrc: thumbHairBlack4,
      avatarImg: avatarHairBlue4,
    },
    {
      id: "hair-blue-5",
      imgSrc: thumbHairBlack5,
      avatarImg: avatarHairBlue5,
    },
    {
      id: "hair-blue-6",
      imgSrc: thumbHairBlack6,
      avatarImg: avatarHairBlue6,
    },
    {
      id: "hair-blue-7",
      imgSrc: thumbHairBlack7,
      avatarImg: avatarHairBlue7,
    },
    {
      id: "hair-blue-8",
      imgSrc: thumbHairBlack8,
      avatarImg: avatarHairBlue8,
    },
    {
      id: "hair-blue-9",
      imgSrc: thumbHairBlack9,
      avatarImg: avatarHairBlue9,
    },
    {
      id: "hair-blue-10",
      imgSrc: thumbHairBlack10,
      avatarImg: avatarHairBlue10,
    },
    {
      id: "hair-blue-11",
      imgSrc: thumbHairBlack11,
      avatarImg: avatarHairBlue11,
    },
    {
      id: "hair-blue-12",
      imgSrc: thumbHairBlack12,
      avatarImg: avatarHairBlue12,
    },
    {
      id: "hair-blue-13",
      imgSrc: thumbHairBlack13,
      avatarImg: avatarHairBlue13,
    },
    {
      id: "hair-blue-14",
      imgSrc: thumbHairBlack14,
      avatarImg: avatarHairBlue14,
    },
    {
      id: "hair-blue-15",
      imgSrc: thumbHairBlack15,
      avatarImg: avatarHairBlue15,
    },
    {
      id: "hair-blue-16",
      imgSrc: thumbHairBlack16,
      avatarImg: avatarHairBlue16,
    },
  ],
}

const apparelState = {
  black: [
    {
      id: "apparel-black-1",
      imgSrc: thumbApparelBlack1,
      avatarImg: avatarApparelBlack1,
    },
    {
      id: "apparel-black-2",
      imgSrc: thumbApparelBlack2,
      avatarImg: avatarApparelBlack2,
    },
    {
      id: "apparel-black-3",
      imgSrc: thumbApparelBlack3,
      avatarImg: avatarApparelBlack3,
    },
    {
      id: "apparel-black-4",
      imgSrc: thumbApparelBlack4,
      avatarImg: avatarApparelBlack4,
    },
  ],
  green: [
    {
      id: "apparel-green-1",
      imgSrc: thumbApparelBlack1,
      avatarImg: avatarApparelGreen1,
    },
    {
      id: "apparel-green-2",
      imgSrc: thumbApparelBlack2,
      avatarImg: avatarApparelGreen2,
    },
    {
      id: "apparel-green-3",
      imgSrc: thumbApparelBlack3,
      avatarImg: avatarApparelGreen3,
    },
    {
      id: "apparel-green-4",
      imgSrc: thumbApparelBlack4,
      avatarImg: avatarApparelGreen4,
    },
  ],
  blue: [
    {
      id: "apparel-blue-1",
      imgSrc: thumbApparelBlack1,
      avatarImg: avatarApparelBlue1,
    },
    {
      id: "apparel-blue-2",
      imgSrc: thumbApparelBlack2,
      avatarImg: avatarApparelBlue2,
    },
    {
      id: "apparel-blue-3",
      imgSrc: thumbApparelBlack3,
      avatarImg: avatarApparelBlue3,
    },
    {
      id: "apparel-blue-4",
      imgSrc: thumbApparelBlack4,
      avatarImg: avatarApparelBlue4,
    },
  ],
  white: [
    {
      id: "apparel-white-1",
      imgSrc: thumbApparelBlack1,
      avatarImg: avatarApparelWhite1,
    },
    {
      id: "apparel-white-2",
      imgSrc: thumbApparelBlack2,
      avatarImg: avatarApparelWhite2,
    },
    {
      id: "apparel-white-3",
      imgSrc: thumbApparelBlack3,
      avatarImg: avatarApparelWhite3,
    },
    {
      id: "apparel-white-4",
      imgSrc: thumbApparelBlack4,
      avatarImg: avatarApparelWhite4,
    },
  ],
  grey: [
    {
      id: "apparel-grey-1",
      imgSrc: thumbApparelBlack1,
      avatarImg: avatarApparelGrey1,
    },
    {
      id: "apparel-grey-2",
      imgSrc: thumbApparelBlack2,
      avatarImg: avatarApparelGrey2,
    },
    {
      id: "apparel-grey-3",
      imgSrc: thumbApparelBlack3,
      avatarImg: avatarApparelGrey3,
    },
    {
      id: "apparel-grey-4",
      imgSrc: thumbApparelBlack4,
      avatarImg: avatarApparelGrey4,
    },
  ],
  orange: [
    {
      id: "apparel-orange-1",
      imgSrc: thumbApparelBlack1,
      avatarImg: avatarApparelOrange1,
    },
    {
      id: "apparel-orange-2",
      imgSrc: thumbApparelBlack2,
      avatarImg: avatarApparelOrange2,
    },
    {
      id: "apparel-orange-3",
      imgSrc: thumbApparelBlack3,
      avatarImg: avatarApparelOrange3,
    },
    {
      id: "apparel-orange-4",
      imgSrc: thumbApparelBlack4,
      avatarImg: avatarApparelOrange4,
    },
  ],
  pattern: [
    {
      id: "apparel-pattern-1",
      imgSrc: thumbApparelPattern1,
      avatarImg: avatarApparelPattern1,
    },
    {
      id: "apparel-pattern-2",
      imgSrc: thumbApparelPattern2,
      avatarImg: avatarApparelPattern2,
    },
    {
      id: "apparel-pattern-3",
      imgSrc: thumbApparelPattern4,
      avatarImg: avatarApparelPattern4,
    },
    {
      id: "apparel-pattern-4",
      imgSrc: thumbApparelPattern5,
      avatarImg: avatarApparelPattern5,
    },
    // {
    //   id: "apparel-pattern-3",
    //   imgSrc: thumbApparelPattern3,
    //   avatarImg: avatarApparelPattern3,
    // },
    // {
    //   id: "apparel-pattern-6",
    //   imgSrc: thumbApparelPattern6,
    //   avatarImg: avatarApparelPattern6,
    // },
  ],
}

const accessoriesState = {
  property: [
    {
      id: "accessories-1",
      imgSrc: thumbAccessories1,
      avatarImg: avatarAccessories1,
    },
    {
      id: "accessories-2",
      imgSrc: thumbAccessories2,
      avatarImg: avatarAccessories2,
    },
    {
      id: "accessories-3",
      imgSrc: thumbAccessories3,
      avatarImg: avatarAccessories3,
    },
    {
      id: "accessories-4",
      imgSrc: thumbAccessories4,
      avatarImg: avatarAccessories4,
    },
    {
      id: "accessories-5",
      imgSrc: thumbAccessories5,
      avatarImg: avatarAccessories5,
    },
    {
      id: "accessories-6",
      imgSrc: thumbAccessories6,
      avatarImg: avatarAccessories6,
    },
    {
      id: "accessories-7",
      imgSrc: thumbAccessories7,
      avatarImg: avatarAccessories7,
    },
    {
      id: "accessories-8",
      imgSrc: thumbAccessories8,
      avatarImg: avatarAccessories8,
    },
    {
      id: "accessories-9",
      imgSrc: thumbAccessories9,
      avatarImg: avatarAccessories9,
    },
    {
      id: "accessories-10",
      imgSrc: thumbAccessories10,
      avatarImg: avatarAccessories10,
    },
    {
      id: "accessories-11",
      imgSrc: thumbAccessories11,
      avatarImg: avatarAccessories11,
    },
  ],
}

const technologyState = {
  light: [
    {
      id: "csharp-light",
      avatarImg: csharpWhite,
      thumbImg: csharpThumb,
    },
    {
      id: "java-light",
      avatarImg: javaWhite,
      thumbImg: javaThumb,

    },
    {
      id: "mongo-light",
      avatarImg: mongoWhite,
      thumbImg: mongoThumb,
    },
    {
      id: "kotlin-light",
      avatarImg: kotlinWhite,
      thumbImg: kotlinThumb,
    },
    {
      id: "mysql-light",
      avatarImg: mysqlWhite,
      thumbImg: mysqlThumb,
    },
    {
      id: "postgres-light",
      avatarImg: postgresWhite,
      thumbImg: postgresThumb,
    },
    {
      id: "flutter-light",
      avatarImg: flutterWhite,
      thumbImg: flutterThumb,
    },
    {
      id: "react-light",
      avatarImg: reactWhite,
      thumbImg: reactThumb,
    },
    {
      id: "nextjs-light",
      avatarImg: nextjsWhite,
      thumbImg: nextjsThumb,
    },
    {
      id: "vue-light",
      avatarImg: vueWhite,
      thumbImg: vueThumb,
    },
    {
      id: "typescript-light",
      avatarImg: typescriptWhite,
      thumbImg: typescriptThumb,
    },
    {
      id: "haskell-light",
      avatarImg: haskellWhite,
      thumbImg: haskellThumb,
    },
    {
      id: "swift-light",
      avatarImg: swiftWhite,
      thumbImg: swiftThumb,
    },
    {
      id: "hasura-light",
      avatarImg: hasuraWhite,
      thumbImg: hasuraThumb,
    },
    {
      id: "go-light",
      avatarImg: goWhite,
      thumbImg: goThumb,
    },
    {
      id: "angular-light",
      avatarImg: angularWhite,
      thumbImg: angularThumb,
    },
    {
      id: "gatsby-light",
      avatarImg: gatsbyWhite,
      thumbImg: gatsbyThumb,
    },
  ],
  dark: [
    {
      id: "csharp-dark",
      avatarImg: csharpBlack,
      thumbImg: csharpThumb,
    },
    {
      id: "java-dark",
      avatarImg: javaBlack,
      thumbImg: javaThumb,

    },
    {
      id: "mongo-dark",
      avatarImg: mongoBlack,
      thumbImg: mongoThumb,
    },
    {
      id: "kotlin-dark",
      avatarImg: kotlinBlack,
      thumbImg: kotlinThumb,
    },
    {
      id: "mysql-dark",
      avatarImg: mysqlBlack,
      thumbImg: mysqlThumb,
    },
    {
      id: "postgres-dark",
      avatarImg: postgresBlack,
      thumbImg: postgresThumb,
    },
    {
      id: "flutter-dark",
      avatarImg: flutterBlack,
      thumbImg: flutterThumb,
    },
    {
      id: "react-dark",
      avatarImg: reactBlack,
      thumbImg: reactThumb,
    },
    {
      id: "nextjs-dark",
      avatarImg: nextjsBlack,
      thumbImg: nextjsThumb,
    },
    {
      id: "vue-dark",
      avatarImg: vueBlack,
      thumbImg: vueThumb,
    },
    {
      id: "typescript-dark",
      avatarImg: typescriptBlack,
      thumbImg: typescriptThumb,
    },
    {
      id: "haskell-dark",
      avatarImg: haskellBlack,
      thumbImg: haskellThumb,
    },
    {
      id: "swift-dark",
      avatarImg: swiftBlack,
      thumbImg: swiftThumb,
    },
    {
      id: "hasura-dark",
      avatarImg: hasuraBlack,
      thumbImg: hasuraThumb,
    },
    {
      id: "go-dark",
      avatarImg: goBlack,
      thumbImg: goThumb,
    },
    {
      id: "angular-dark",
      avatarImg: angularBlack,
      thumbImg: angularThumb,
    },
    {
      id: "gatsby-dark",
      avatarImg: gatsbyBlack,
      thumbImg: gatsbyThumb,
    },
  ],
}

const badgeState = {
  property: [
    {
      id: "has-con-badge",
      avatarImg: hasConBadge,
    },
  ]
}

export { furStyleState, smileState, hairState, apparelState, accessoriesState, technologyState, bgState, badgeState };
