import React from "react";

import Layout from "../../../components/hasuracon2022/Layout";
import CreateAvatar from "../../../components/hasuracon2022/CreateAvatar";
// import Schedule from "../../../components/hasuracon2022/Schedule";
// import Speakers from "../../../components/hasuracon2022/Speakers";
// import HasuraAwards from "../../../components/hasuracon2022/HasuraAwards";
// import AboutHasura from "../../../components/hasuracon2022/AboutHasura";
// import JoinHasuraTeam from "../../../components/hasuracon2022/JoinHasuraTeam";
// import SponsorHasuraCon from "../../../components/hasuracon2022/SponsorHasuraCon";
import Seo from "../../../components/seo";

// import Schedule from "../../../components/hasuracon2022/Schedule";
// import Speakers from "../../../components/hasuracon2022/Speakers";
// import HasuraAwards from "../../../components/hasuracon2022/HasuraAwards";
// import AboutHasura from "../../../components/hasuracon2022/AboutHasura";
// import JoinHasuraTeam from "../../../components/hasuracon2022/JoinHasuraTeam";
// import SponsorHasuraCon from "../../../components/hasuracon2022/SponsorHasuraCon";
import { prefetchPathname } from "gatsby";

const canonicalUrl = "https://hasura.io/events/hasura-con-2022/";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2022.png",
};

// const [hasConId, setHasConId] = useState("");
// useEffect(() => {
//     const searchParams = new URLSearchParams(location.search);
//     const searchHasConId = searchParams.get("hasCon");
//     if (searchHasConId && searchHasConId != "") {
//       setHasConId(searchHasConId);
//     }
//   }, [location.search]);

//ogImage: `http://res.cloudinary.com/dh8fp23nd/image/upload/${hasConId}.jpg`,
// const ogImage = {
//   ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2022.png"
// };
const HasuraCon2022 = props => (
  <Layout location={props.location} noHeader={true} noFooter={true}>
    <CreateAvatar location={props.location} />
    <Seo
      title="HasuraCon'22"
      description="The Annual Hasura User Conference. Bringing the Hasura Team and community together, online."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    {/*<AboutHasura />
    <Schedule />
    <HasuraAwards />
    <Speakers />
    <JoinHasuraTeam />
    <SponsorHasuraCon />*/}
  </Layout>
);

export default HasuraCon2022;
